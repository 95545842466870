import React from "react";
import SectionHeader from "../common/SectionHeader";
import CTA from "../common/CTA";
import hackathon from "../../assets/images/hackathon.png";
import hemanth from "../../assets/images/hemanth.jpeg";
import srikantha from "../../assets/images/srikantha.jpeg";

const Hackathon = () => {
  return (
    <main className="max-w-7xl mx-auto md:px-0 px-6">
      {/* Hero Section */}
      <div className="flex flex-col md:flex-row items-center md:items-start md:justify-between px-6 py-12 md:py-20">
        {/* Left Side - Image */}
        <div className="w-full md:w-1/2 flex justify-center md:justify-start">
          <img src={hackathon} alt="Hackathon" className="max-w-full rounded-md shadow-lg" />
        </div>

        {/* Right Side - Title, Description, and Register Button */}
        <div className="w-full md:w-1/2 mt-8 md:mt-0 md:pl-12 text-center md:text-left">
          <h1 className="text-3xl md:text-4xl mt-14 uppercase font-geist-mono">Achieve Dreams</h1>
          <h2 className="text-3xl md:text-4xl font-bold mb-4 mt-2">Design+Build Hackathon</h2>
          <p className="text-gray-600 mb-6 text-lg">
            Join us for the QTR Design+Build Hackathon, a week-long event designed to spark creativity, innovation, and collaboration. This is your chance to reimagine QTR's future by crafting engaging solutions that leverage AI, enhance productivity, and redefine platform design. Push boundaries,
            showcase your talent, and compete for exciting prizes while connecting with like-minded individuals.
          </p>
          <div className="bg-gray-100 rounded-md mb-6 space-y-4">
            <div className="border-b pb-4">
              <strong>Kickoff:</strong> December 9, 2024
            </div>
            <div className="border-b pb-4">
              <strong>Team Presentations & Judging:</strong> December 14, 2024
            </div>
            <div className="pb-4">
              <strong>Location:</strong> Online
            </div>
          </div>
          <a
            href="https://forms.gle/Mr7EuPgohiQxmuDd9"
            target="_blank"
            rel="noreferrer"
            className="text-2xl py-5 bg-black text-white font-geist-mono px-10 inline-flex items-center justify-center cursor-pointer outline-none focus:outline-none transition-transform duration-300 ease-in-out hover:-rotate-6">
            Register Now
          </a>
        </div>
      </div>

      {/* What We're Looking For */}
      <section className="flex justify-center">
        <div className="w-full max-w-3xl">
          <SectionHeader title="Rules" description="" />
          <div className="text-left">
            Develop a feature or project focused on productivity, time management, goal setting, or task management. We’re primarily interested in a proof of concept, so there’s no need to implement authentication or overcomplicate the design—keep it simple and prioritize core functionality. We're
            looking for ideas that could potentially enhance QTR. To showcase your project, you can either provide a link to a live demo or share a video demonstrating it in action. Your submission can be a web or mobile app, built with any technology stack of your choice. Please ensure your code is
            checked into GitHub and share the repository link with us. You’re welcome to work individually or collaborate with a team.
          </div>
        </div>
      </section>

      {/* What We're Looking For */}
      <section className="flex justify-center">
        <div className="w-full max-w-3xl">
          <SectionHeader title="What We're Looking For" description="" />
          <div className="text-left">
            <p>
              QTR is an all-in-one productivity app that is centered around task, time, and goal management. QTR also includes features like notes, journaling, habit tracking, and focus tools to supercharge your productivity journey. We’re on the lookout for innovative and creative features to make
              QTR even better. If you’re looking for inspiration, here are some ideas to get you started:
            </p>
            <ul className="list-disc pl-6 mt-4">
              <li>AI-powered suggestions to streamline task creation and planning</li>
              <li>Interactive mind-mapping tools for brainstorming and visual organization</li>
              <li>Goal tracking with real-time progress visualizations and milestone alerts</li>
              <li>Customizable focus timer with options like ambient sounds or guided work sessions</li>
              <li>Comprehensive habit tracking with streaks, reminders, and rewards</li>
              <li>AI-enhanced features, such as intelligent prioritization or calendar optimization</li>
              <li>A complete redesign of the QTR interface, focusing on usability and aesthetics</li>
              <li>A companion mobile app to bring QTR’s productivity power on the go</li>
              <li>Team collaboration tools, such as shared task boards or real-time notes</li>
              <li>Gamification elements, like leveling up as you complete tasks or achieve goals</li>
              <li>Integration with popular apps like Slack, Google Calendar, or Notion</li>
              <li>Smart journaling prompts that adapt based on recent activity or goals</li>
              <li>Advanced analytics to track productivity trends and suggest improvements</li>
              <li>A digital wellness feature that helps balance productivity and relaxation</li>
              <li>Personalized daily dashboards tailored to user habits and preferences</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Judging Criteria */}
      <section className="flex justify-center">
        <div className="w-full max-w-3xl">
          <SectionHeader title="Judging Criteria" description="" />
          <div className="space-y-4 text-gray-700 text-left">
            <div className="border-b pb-4">
              <strong>Innovation/Creativity (25 points):</strong> Creativity and originality in the approach.
            </div>
            <div className="pb-4">
              <strong>Impact (25 points):</strong> Feasibility and overall value of the solution.
            </div>
            <div className="border-b pb-4">
              <strong>Functionality (20 points):</strong> How well the solution works and fulfills its intended purpose.
            </div>
            <div className="border-b pb-4">
              <strong>UX/Design (20 points):</strong> Design elegance, simplicity, and user experience.
            </div>

            <div className="pb-4">
              <strong>Presentation (10 points):</strong> Clarity, organization, and persuasiveness of the presentation.
            </div>
          </div>
        </div>
      </section>

      {/* Prizes */}
      <section className="flex justify-center">
        <div className="w-full max-w-3xl">
          <SectionHeader title="Prizes" description="" />
          <div className="space-y-6 text-gray-700 text-left">
            <div className="flex items-center border-b pb-6">
              <img src="https://www.apple.com/v/watch/bo/images/overview/select/product_s10__deak4mdempoy_xlarge_2x.png" alt="Apple Watch" className="border w-32 h-32 mr-4 object-cover" />
              <p>
                <strong>Winner:</strong> Receive a brand-new Apple Watch to help you stay productive and on top of your game.
              </p>
            </div>
            <div className="flex items-center border-b pb-6">
              <img src="https://m.media-amazon.com/images/I/61+WYAjltpL._AC_SY300_SX300_.jpg" alt="Noise Canceling Headphones" className="w-32 h-32 mr-4 object-cover" />
              <p>
                <strong>Runner-up:</strong> Get noise-canceling headphones — Soundcore Life Q30 by Anker.
              </p>
            </div>
            <div className="flex items-center border-b pb-6">
              <p>All winners will also receive a 1-year subscription to QTR, giving you access to advanced features, and unlimited projects.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Judges */}
      <section className="flex justify-center px-4">
        <div className="w-full max-w-3xl">
          <SectionHeader title="The Judges" description="" />

          {/* Judge 1 */}
          <div className="flex flex-col md:flex-row items-center space-y-6 md:space-y-0 md:space-x-6 mt-8">
            <img src={hemanth} alt="Venkatesh Gorur" className="w-80 h-80 md:w-80 object-cover rounded-lg" />
            <div className="text-left">
              <p>
                <a href="https://www.linkedin.com/in/hemanthgorur/" target="_blank" rel="noreferrer" className="text-blue-900 hover:underline">
                  Venkatesh Gorur
                </a>{" "}
                is an accomplished engineering leader with over 20 years of experience in building B2B and customer-facing SaaS solutions. He has worked across diverse industries, including Finance, Healthcare, Software Services, and Travel. One of his most notable achievements is leading the
                development of the vaccines.gov platform for the CDC, enabling seamless vaccine distribution during the COVID-19 pandemic.
              </p>
            </div>
          </div>

          {/* Judge 2 */}
          <div className="flex flex-col md:flex-row items-center space-y-6 md:space-y-0 md:space-x-6 mt-8">
            <img src={srikantha} alt="Srikanta" className="w-80 h-80 md:w-80 object-cover rounded-lg" />
            <div className="text-left">
              <p>
                <a href="http://srikanta.design" target="_blank" rel="noreferrer" className="text-blue-900 hover:underline">
                  Srikanta
                </a>{" "}
                is a San Francisco-based UX leader with over 20 years of experience across Finance, Healthcare, Technology, and Enterprise Software. A key architect of Oracle's Redwood Design System, he has driven adoption across 100+ products, impacting 10,000+ users and generating over $1 billion
                in revenue. Previously, he led iconic projects at Yahoo! Castlight Health, Thoughtworks, InMobi and HP. Passionate about art, photography, and strategic design, Srikanta continues to craft experiences that inspire and innovate.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* CTA */}
      <div className="mt-20">
        <a
          href="https://forms.gle/Mr7EuPgohiQxmuDd9"
          target="_blank"
          rel="noreferrer"
          className="text-2xl py-5 bg-black text-white font-geist-mono px-10 inline-flex items-center justify-center cursor-pointer outline-none focus:outline-none transition-transform duration-300 ease-in-out hover:-rotate-6 ">
          Sign up for the hackathon
        </a>
      </div>
    </main>
  );
};

export default Hackathon;
